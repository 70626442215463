/** @jsx jsx */
import React from "react"; //eslint-disable-line no-unused-vars
import ReactMarkdown from "react-markdown";
import { jsx } from "theme-ui";

const MarkdownRenderer = ({ className, children, small, large }) => {
  return (
    <div
      className={className}
      sx={{
        strong: { color: "primary" },
        p: {
          fontSize: [small, null, null, large],
          ":first-of-type": { mt: 0 },
        },
      }}
    >
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;
